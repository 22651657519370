const services = [{
    name: ' [BE] - Content',
    git: 'https://github.com/Akelius-Languages-Online/university-content-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/content',
    testing: 'https://business-school-testing.development.languages.akelius.com/content',
    staging: 'https://business-school-staging.production.languages.akelius.com/content',
    production: 'https://business-school.production.languages.akelius.com/content'
}, {
    name: ' [BE] - Authorization',
    git: 'https://github.com/Akelius-Languages-Online/university-authorization-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/authorization',
    testing: 'https://business-school-testing.development.languages.akelius.com/authorization',
    staging: 'https://business-school-staging.production.languages.akelius.com/authorization',
    production: 'https://business-school.production.languages.akelius.com/authorization'
}, {
    name: ' [BE] - Root',
    git: 'https://github.com/Akelius-Languages-Online/university-root-service/commit/',
    swagger: '/swagger-ui.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/root',
    testing: 'https://business-school-testing.development.languages.akelius.com/root',
    staging: 'https://business-school-staging.production.languages.akelius.com/root',
    production: 'https://business-school.production.languages.akelius.com/root'
}, {
    name: ' [BE] - User',
    git: 'https://github.com/Akelius-Languages-Online/university-user-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/user',
    testing: 'https://business-school-testing.development.languages.akelius.com/user',
    staging: 'https://business-school-staging.production.languages.akelius.com/user',
    production: 'https://business-school.production.languages.akelius.com/user'
},{
    name: ' [BE] - LRS - Proxy',
    git: 'https://github.com/Akelius-Languages-Online/university-lrs-proxy/commit/',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/lrsproxy',
    testing: 'https://business-school-testing.development.languages.akelius.com/lrsproxy',
    staging: 'https://business-school-staging.production.languages.akelius.com/lrsproxy',
    production: 'https://business-school.production.languages.akelius.com/lrsproxy'
},{
    name: ' [BE] - Statistics',
    git: 'https://github.com/Akelius-Languages-Online/university-statistic-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/statistics',
    testing: 'https://business-school-testing.development.languages.akelius.com/statistics',
    staging: 'https://business-school-staging.production.languages.akelius.com/statistics',
    production: 'https://business-school.production.languages.akelius.com/statistics'
},{
    name: ' [BE] Lock-service',
    git: 'https://github.com/Akelius-Languages-Online/university-lock-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/lock',
    testing: 'https://business-school-testing.development.languages.akelius.com/lock',
    staging: 'https://business-school-staging.production.languages.akelius.com/lock',
    production: 'https://business-school.production.languages.akelius.com/lock'

},{
    name: ' [BE] GDPR-compliance-service',
    git: 'https://github.com/Akelius-Languages-Online/university-gdpr-compliance-service/commit/',
    swagger: '/graphiql',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/gdpr',
    testing: 'https://business-school-testing.development.languages.akelius.com/gdpr',
    staging: 'https://business-school-staging.production.languages.akelius.com/gdpr',
    production: 'https://business-school.production.languages.akelius.com/gdpr'

}, {
    name: ' [BE] - Media',
    git: 'https://github.com/Akelius-Languages-Online/generator-media-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/media',
    testing: 'https://business-school-testing.development.languages.akelius.com/media',
    staging: 'https://business-school-staging.production.languages.akelius.com/media',
    production: 'https://business-school.production.languages.akelius.com/media'

}, {
    name: ' [BE] - Keyboard Service',
    git: 'https://github.com/Akelius-Languages-Online/university-keyboard-app/commit/',
    development: 'https://university-keyboard-app.development.languages.akelius.com/keyboard',
    testing: '',
    staging: '',
    production: 'https://university-keyboard-app.production.languages.akelius.com/keyboard'
}, {
    name: '[BE] - Secret Code Service',
    git: 'https://github.com/Akelius-Languages-Online/university-secret-code-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/secret-code',
    testing: 'https://business-school-testing.development.languages.akelius.com/secret-code',
    staging: 'https://business-school-staging.production.languages.akelius.com/secret-code',
    production: 'https://business-school.production.languages.akelius.com/secret-code'
}, {
    name: '[BE] - LLM Service',
    git: 'https://github.com/Akelius-Languages-Online/llm-service/commit/',
    swagger: '/swagger-ui/index.html',
    info: '/actuator/info',
    development: 'https://business-school-development.development.languages.akelius.com/llm',
    production: 'https://business-school.production.languages.akelius.com/llm'
}, {
    name: '[FE] - Platform Languages',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/university-platform-frontend/commit/',
    info: '/git-info.json',
    report: 'https://business-school-platform-end2end.development.languages.akelius.com',
    development: 'https://business-school-development.development.languages.akelius.com',
    testing: 'https://business-school-testing.development.languages.akelius.com',
    staging: 'https://business-school-staging.production.languages.akelius.com',
    production: 'https://languages.akelius.com'

}, {
    name: '[FE] - Platform Teachers',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/university-platform-frontend/commit/',
    info: '/git-info.json',
    report: 'https://business-school-platform-end2end.development.languages.akelius.com',
    development: 'https://teachers-development.development.languages.akelius.com/',
    testing: 'https://teachers-testing.development.languages.akelius.com',
    staging: 'https://teachers-staging.production.languages.akelius.com',
    production: 'https://teachers.akelius.com/'
}, {
    name: '[FE] - Math Platform',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/math-platform/commit/',
    info: '/git-info.json',
    report: 'https://akelius-education-website-development.development.languages.akelius.com',
    development: 'https://math-platform-development.development.languages.akelius.com/',
    testing: 'https://math-platform-testing.development.languages.akelius.com/',
    production: 'https://math.production.languages.akelius.com/'
}, {
    name: '[FE] - Teacher Training Platform',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/teacher-training-platform/commit/',
    info: '/git-info.json',
    development: 'https://teacher-training-platform-development.development.languages.akelius.com/',
    production: 'https://teacher-training.production.languages.akelius.com'
}, {
    name: '[FE] - Classroom Admin',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/classroom-admin/commit/',
    info: '/git-info.json',
    report: 'https://classroom-admin-end2end.development.languages.akelius.com',
    development: 'https://classroom-admin-development.development.languages.akelius.com/',
    testing: 'https://classroom-admin-testing.development.languages.akelius.com',
    staging: 'https://classroom-admin-staging.production.languages.akelius.com',
    production: 'https://classroom-admin.production.languages.akelius.com/'
}, {
    name: '[FE] - Platform SWEDEN',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/university-platform-frontend/commit/',
    info: '/git-info.json',
    development: 'https://business-school-development-sv.development.languages.akelius.com',
    production: 'https://business-school-sweden.production.languages.akelius.com'
}, {
    name: '[FE] - Asset App ',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/university-asset-app/commit/',
    info: '/git-info.json',
    report: 'https://business-school-generator-e2e.development.languages.akelius.com',
    development: 'https://business-school-asset-app-development.development.languages.akelius.com',
    testing: 'https://business-school-asset-app-testing.development.languages.akelius.com',
    staging: 'https://business-school-asset-app-staging.production.languages.akelius.com',
    production: 'https://business-school-asset-app.production.languages.akelius.com'
}, {
    name: '[FE] - Generator Consumer ',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/university-consumer-kit/commit/',
    info: '/git-info.json',
    development: 'https://business-school-gen-consumer-dev.development.languages.akelius.com'
}, {
    name: '[FE] - Chatbot',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/chatbot-frontend/commit/',
    info: '/git-info.json',
    development: 'https://chatbot-frontend-kit-development.development.languages.akelius.com',
    production: 'https://chatbot-frontend-kit-production.production.languages.akelius.com'
}, {
    name: 'Keycloak',
    link: true,
    development: 'https://business-school-keycloak-development.development.languages.akelius.com',
    testing: 'https://business-school-keycloak-testing.development.languages.akelius.com',
    staging: 'https://business-school-keycloak-staging.production.languages.akelius.com',
    production: 'https://business-school-keycloak.production.languages.akelius.com'
}, {
    name: '[MOB] - Build Box',
    link: true,
    git: 'https://github.com/Akelius-Languages-Online/android-build-box/commit/',
    development: 'https://android-app-box.development.languages.akelius.com',
}, {
    name: 'RabbitMq',
    link: true,
    development: 'https://business-school-rabbitmq-development.development.languages.akelius.com',
    testing: 'https://business-school-rabbitmq-testing.development.languages.akelius.com',
    staging: 'https://business-school-rabbitmq-staging.production.languages.akelius.com',
    production: 'https://business-school-rabbitmq-production.production.languages.akelius.com'
}];

export {services};
